/**
 * Initializes table filtering functionality.
 *
 * This function assigns an event listener to each 'input' HTML element with a 'data-filter-table' attribute.
 * The event listener is triggered on the 'keyup' event, and calls the 'filterTable' function passing the
 * 'input' element and the 'table' element as arguments.
 * The 'table' element is determined by the value of the 'data-filter-table' attribute of the 'input' element,
 * which should be the ID of the 'table' element to be filtered.
 *
 * @example
 * // HTML structure
 * // <input id="filterTable" data-filter-table="myTable">
 * // <table id="myTable">...</table>
 *
 * // Initialize table filtering
 * initTableFilter();
 *
 * @export
 */
export function initTableFilter() {
  // Get all input tags with a data-filter-table attribute
  const inputs = document.querySelectorAll('input[data-filter-table]');
  for (let input of inputs) {
    let table = document.getElementById(input.dataset.filterTable);
    input.addEventListener('keyup', () => filterTable(input, table));
  }
}

/**
 * Filters table rows based on the given input and data attributes in the table.
 *
 * @param {HTMLInputElement} input - The input field that contains the filter value.
 * @param {HTMLTableElement} table - The table element that contains rows to be filtered.
 */
function filterTable(input, table) {
  // A function that removes accents from a string, converts it to uppercase.
  // This helps standardize the string for comparison during filtering.
  const normalizeForFilter = (str) =>
    str
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .toUpperCase();

  // The filter value is the normalized version of the input value.
  const filter = normalizeForFilter(input.value);

  // Get all table row elements from the table.
  const rows = table.querySelectorAll('tbody tr');

  // Iterate over each table row.
  for (let row of rows) {
    // Check if any visible cell in the row matches the filter.
    // A cell matches the filter if it has a 'data-search' attribute and its normalized content includes the filter string.
    let visibleDataMatch = Array.from(row.getElementsByTagName('td')).some(
      (td) => td.hasAttribute('data-search') && normalizeForFilter(td.textContent).includes(filter),
    );

    // Check if any non-visible data attribute of the row matches the filter.
    // An attribute matches the filter if its name starts with 'search' and its normalized value includes the filter string.
    let nonVisibleDataMatch = Object.keys(row.dataset)
      .filter((key) => key.startsWith('search'))
      .some((key) => normalizeForFilter(row.dataset[key]).includes(filter));

    // If either a visible cell or a non-visible attribute matches the filter, the row is displayed.
    // Otherwise, it is hidden.
    row.style.display = visibleDataMatch || nonVisibleDataMatch ? '' : 'none';
  }
}

/**
 * Initializes table sorting functionality for tables with a 'table-sortable' class.
 *
 * This function assigns a 'click' event listener to the headers (TH elements) of each table with a 'table-sortable' class.
 * When a header is clicked, the 'sortTable' function is called, which sorts the table's rows based on the column of the clicked header.
 *
 * Headers that are intended to trigger sorting should include a 'data-sort' attribute.
 * This attribute specifies the type of sorting to be used - 'num' for numeric sorting, or any other string for alphabetic sorting.
 *
 * @example
 * // HTML structure
 * // <table class="table-sortable">
 * //   <thead>
 * //     <tr>
 * //       <th data-sort="num">Header 1</th>
 * //       <th data-sort>Header 2</th>
 * //     </tr>
 * //   </thead>
 * //   ...
 * // </table>
 *
 * // Initialize table sorting
 * initTableSort();
 *
 * @export
 */
export function initTableSort() {
  // Get all sortable tables
  let tables = document.getElementsByClassName('table-sortable');

  for (let table of tables) {
    // Get the table header
    let thead = table.getElementsByTagName('thead')[0];

    // Add click event on the table header
    thead.addEventListener('click', (event) => {
      // If clicked element is TH (table header)
      if (event.target.nodeName === 'TH') {
        // Get column of clicked header
        let col = Array.prototype.indexOf.call(event.target.parentNode.children, event.target);
        // Get sorting type
        let type = event.target.getAttribute('data-sort');
        // Call the sort function
        sortTable(table, col, type);
      }
    });
  }
}

/**
 * Sort a specified table column based on a sorting type.
 * Sort direction is indicated through CSS class ("asc-alpha", "desc-alpha", "asc-num", "desc-num")
 *
 * @param {HTMLElement} table - The table to sort.
 * @param {number} col - The index of the column to sort.
 * @param {string} type - The type of the sort, 'num' for numeric or any other string for alphabetic. Corresponds to the 'data-sort' attribute of the TH.
 */
function sortTable(table, col, type) {
  const headers = Array.from(table.getElementsByTagName('TH'));
  const dir = headers[col].classList.contains('asc-' + type) ? 'desc' : 'asc';

  // Remove asc and desc classes from all headers
  headers.forEach((header) => {
    header.classList.remove('asc-alpha', 'desc-alpha', 'asc-num', 'desc-num');
  });

  const tbody = table.getElementsByTagName('tbody')[0];
  const rows = Array.from(tbody.rows).map((row) => {
    const td = row.getElementsByTagName('TD')[col];
    const value = type == 'num' ? parseInt(td.dataset.sort) : normalizeForSort(td.innerHTML);

    return {
      element: row,
      value: value,
    };
  });

  // Sorting
  rows.sort((a, b) => {
    if (dir == 'asc') {
      return a.value > b.value ? 1 : -1;
    } else {
      return a.value < b.value ? 1 : -1;
    }
  });

  // Using DocumentFragment to reduce reflow
  const fragment = document.createDocumentFragment();
  rows.forEach((row) => fragment.appendChild(row.element));
  tbody.appendChild(fragment);

  // Add dir and type class to the sorted header
  headers[col].classList.add(dir + '-' + type);
}

/**
 * Normalizes a string in preparation for sorting or comparison.
 * This function performs several operations to prepare a string for
 * "natural" sorting, making string comparisons more robust and intuitive.
 *
 * The operations are as follows:
 * 1. Converts all characters to uppercase for case-insensitive comparisons.
 * 2. Uses Unicode normalization to decompose accented characters into their base characters and separate diacritical marks, then removes the diacritical marks.
 * 3. Replaces sequences of one or more whitespace characters with a single space.
 * 4. Removes leading and trailing spaces.
 *
 * @param {string} str - The string to be normalized.
 * @returns {string} The normalized string.
 */
function normalizeForSort(str) {
  return str
    .toUpperCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/\s+/g, ' ')
    .trim();
}
