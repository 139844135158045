/**
 * Router class for managing page-specific JavaScript initialization.
 *
 * This class implements a simple routing mechanism to execute specific JavaScript
 * functions based on the `date-route` attribute .
 */

class Router {
  constructor() {
    this.routes = {};
  }

  async init() {
    const routeElement = document.querySelector('[data-route]');
    if (routeElement) {
      const route = routeElement.getAttribute('data-route');
      await this.loadRouteModule(route);
    }
  }

  async loadRouteModule(route) {
    if (route) {
      console.debug(`Loading Javascript module for route: ${route}`);
      try {
        const module = await import(`./routes/${route}.js`);
        if (typeof module.default === 'function') {
          module.default();
        }
      } catch (error) {
        console.error(`Failed to load module for ${route}:`, error);
      }
    } else {
      console.debug('No matching route found');
    }
  }
}

export default new Router();
